import React, { useState, useEffect, useRef } from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"
import ModalImg from "../../images/new-hero-Background.png"
import { Link } from "gatsby"

const QuickbaseModal = ({
  onMobile,
  buttonLabel,
  heading,
  subHeading,
  btnLink,
  modalImg,

  compLogo,
}) => {
  const modalContainer = useRef(null)
  const [openOtherMessage, setOpenOtherMessage] = useState(false)
  const currentUrl = typeof window !== "undefined" ? window.location.href : ""

  useEffect(() => {
    let timeout
    if (onMobile) {
      timeout = setTimeout(() => {
        const modalContainerRef = modalContainer.current
        if (modalContainerRef) {
          const finishSurvey = sessionStorage.getItem("CitizenDev")
          if (!finishSurvey) {
            modalContainerRef.style.display = "block"
            sessionStorage.setItem("CitizenDev", true)
          }
        }
      }, 8000)
    } else {
      timeout = setTimeout(() => {
        document.addEventListener("mouseleave", e => {
          const modalContainerRef = modalContainer.current
          if (!e.toElement && !e.relatedTarget && modalContainerRef) {
            const finishSurvey = sessionStorage.getItem("CitizenDev")
            if (!finishSurvey) {
              modalContainerRef.style.display = "block"
              sessionStorage.setItem("CitizenDev", true)
            }
          }
        })
      }, 6000)
    }

    return () => clearTimeout(timeout)
  }, [onMobile])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem("CitizenDev")
    }
  })

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 5,
          top: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
          }}
        >
          <div className="vh-100 position-relative">
            <div className="container">
              <div className="row vh-100">
                <div className="col-lg-8 align-self-center m-auto px-0">
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="rounded"
                      style={{
                        padding: "40px 60px 50px 60px",
                        background: `linear-gradient(90deg, rgba(138,73,161,0.8) 0%, rgba(138,73,161,0.8) 100%),url(${ModalImg}) `,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-11 align-self-center mx-auto text-center p-lg-0">
                          <div
                            id="clost-btn"
                            onClick={onModalClose}
                            className="close-btn exit-intent-close-btn"
                            style={{ top: "-60px", right: "-100px" }}
                          >
                            x
                          </div>
                          <div>
                            <h2 className="font-weight-bold text-white mb-3">
                              Need Quickbase Support?
                            </h2>
                            <h5 className="mb-3 text-white">
                              Work with Elite Quickbase Partners. We can help
                              you build the low-code applications you need
                            </h5>
                            <div className="btn-box mt-5 text-center">
                              <Link
                                href="https://quandarycg.com/"
                                id="modal-button"
                                className="text-white font-weight-bold text-uppercase"
                                onClick={onModalClose}
                                style={{ padding: "1rem 2rem" }}
                              >
                                Learn More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuickbaseModal
